import "@hotwired/turbo";
import { Tooltip } from "bootstrap";
import * as ActiveStorage from "@rails/activestorage";
import "./controllers";

ActiveStorage.start();

addEventListener('turbo:frame-load', () => {
  document
    .querySelectorAll('[data-bs-toggle="tooltip"]')
    .forEach((element) => new Tooltip(element));
});
